<template>
  <div class="base-container">
    <vxe-toolbar perfect size="mini">
      <template v-slot:buttons>
        <div class="cf table-header-padding">
          <div class="fl">
            <el-select v-model="search.productId" clearable filterable placeholder="选择产品" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in product" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.spaceNameId" clearable filterable placeholder="选择仓库" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in spaceName" :key="index" :label="item.spaceName" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="fr">
            <el-tooltip class="item" effect="dark" content="查询" placement="top">
              <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="清空" placement="top">
              <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
              <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
              <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="stock" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow
      @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="spaceName" min-width="120" title="仓库名称"></vxe-table-column>
      <vxe-table-column field="productName" min-width="150" title="产品名称"></vxe-table-column>
      <vxe-table-column field="locationName" min-width="150" title="货位"></vxe-table-column>
      <vxe-table-column field="stockNum" min-width="150" title="库存量"></vxe-table-column>
    </vxe-table>
    <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
      :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
      <template v-slot:left>
        <div class="fl footer-check">
          <p>
            已选
            <b style="font-size: 10px">{{ checkLength }}</b> 条
          </p>
        </div>
      </template>
    </vxe-pager>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'stock',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 190 + 'px',
      allAlign: 'center',
      loading: false,
      checkBox: [],
      checkLength: 0,
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      search: {},
      isNull: ['', null, undefined],
    }
  },
  created() {
    this.init()
  },
  computed: { ...mapGetters(['product', 'spaceName'])},
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.stock) {
        this.tableHeight = window.innerHeight - this.$refs.stock.$el.offsetTop - 62 + 'px'
      }
    }
    this.$store.dispatch('getProduct')
    this.$store.dispatch('getSpaceName')
  },
  methods: {
    init() {
      this.loading = true
      const params = {
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
        },
      }
      if (!this.isNull.includes(this.search.productId)) params.params.productId = this.search.productId
      if (!this.isNull.includes(this.search.spaceNameId)) params.params.spaceNameId = this.search.spaceNameId
      this.$axios
        .get('/order/stock/list', params)
        .then((res) => {
          if (res) {
            const data = res.data.data
            this.data = data.list
            this.page.currentPage = data.pageNum
            this.page.totalResult = data.total
            this.checkBox = []
            this.checkLength = 0
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    exportDataEvent() {
      this.$refs.stock.openExport()
    },
    printDataEvent() {
      this.$refs.stock.openPrint()
    },
    /** 选择条数 */
    changeBoxChange() {
      this.checkBox = this.$refs.stock.getCheckboxRecords()
      this.checkLength = this.$refs.stock.getCheckboxRecords().length
    },
    /** 条数更改 */
    handlePageChange({ currentPage, pageSize }) {
      const _this = this
      _this.page.currentPage = currentPage
      _this.page.pageSize = pageSize
      _this.init()
    },
    searchInit() {
      this.page.currentPage = 1
      this.init()
    },
  },
}
</script>

<style scoped></style>
